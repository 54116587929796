import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { StockRouteNames, StockRoutePathNames } from '../enums';
import { beforeSitesListEnter, beforeBusinessUnitEnter } from './guards/index';

const SitesList = () => import('../components/sites-list/SitesList.vue');
const BusinessUnit = () => import('../components/business-unit/BusinessUnit.vue');

const sitesListRoute = {
  path: buildChildURL(StockRoutePathNames.SITES),
  name: StockRouteNames.STOCK_SITES,
  component: SitesList,
  beforeEnter: beforeSitesListEnter,
} as RouteRecordRaw;

const businessUnitRoute = {
  path: buildChildURL(
    StockRoutePathNames.ID,
    StockRoutePathNames.UNIT,
    StockRoutePathNames.UNIT_ID,
  ),
  name: StockRouteNames.STOCK_BUSINESS_UNIT,
  component: BusinessUnit,
  beforeEnter: beforeBusinessUnitEnter,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [sitesListRoute, businessUnitRoute];

export default childRoutes;
