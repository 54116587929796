<template>
  <div :class="rootClasses">
    <label v-if="$slots.label" :id="`label-${id}`">
      <slot name="label" />
    </label>

    <!-- PrimeVue InputNumber has an weird intended behaviour by them which only validates and update the modelValue by:
      1 - pressing enter
      2 - on blur
      3 - using arrows or the increase/decrease buttons

      Due that community opened several issues related to that, but they intend to keep this behaviour,
      but they added the @input callback to do what the dev needs.
    -->
    <PVInputNumber
      :id="id"
      v-model="value"
      :placeholder="placeholder"
      v-bind="$attrs"
      @focus="focused = true"
      @blur="focused = false"
      @input="checkInput()"
      ref="pvInputNumberRef"
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot v-if="slot" :name="slot" v-bind="scope" />
      </template>
    </PVInputNumber>

    <slot v-if="$slots.endAdornment" name="endAdornment" />

    <span v-if="$slots.rightSideLabel" class="ml-2">
      <slot name="rightSideLabel" />
    </span>

    <span v-if="$slots.helperText" class="caption" :id="`helper-text-${id}`">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script lang="ts">
export default { name: 'InputNumber', inheritAttrs: false };
</script>

<script setup lang="ts">
import { computed, useAttrs, ref, useSlots } from 'vue';
import PVInputNumber from 'primevue/inputnumber';
import { useInputState } from '../composables';
import { useElementSize } from '@vueuse/core';
import type { ClassType } from '../composables/input/use-input-state';

defineProps({
  id: {
    type: String,
    default: 'number-input',
  },
  placeholder: String,
});

const attrs = useAttrs();
const slots = useSlots();

const pvInputNumberRef = ref();
const { height, width } = useElementSize(pvInputNumberRef);

const value = defineModel({ type: Number });

const { focused, classes } = useInputState(attrs);

const rootClasses = computed<ClassType[]>(() => [
  ...classes.value,
  { 'p-input-icon-right': slots.endAdornment },
]);

const isDirty = ref<boolean>(false);
const checkInput = () => {
  isDirty.value = true;
};

defineExpose({ value, isDirty, height, width });
</script>
