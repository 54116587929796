<template>
  <div class="c-page-panel" :id="`panel-${index}`">
    <div :class="headerClasses" :id="`panel-header-${index}`">
      <div class="flex justify-content-between align-items-center w-full">
        <div class="flex flex-column">
          <div class="flex">
            <Skeleton width="10rem" v-if="loading" />
            <slot v-else-if="$slots.header" name="header" />
            <h2 v-else class="font-semibold">{{ panel.header }}</h2>
            <span
              v-if="panel?.headerTooltip"
              v-tooltip.bottom="panel.headerTooltip"
              class="material-symbols-rounded ml-2"
              >info</span
            >
          </div>
          <div class="flex" v-if="panel?.headerSubtitle">
            <span class="header-subtitle" v-if="!loading"> {{ panel?.headerSubtitle }}</span>
            <Skeleton width="4rem" class="mt-1" v-else-if="loading" />
          </div>
        </div>

        <div class="flex align-items-center" v-if="panel?.actionsComponent">
          <div class="flex align-items-center">
            <component :is="panel.actionsComponent" v-bind="panel.actionsProps" v-if="!loading" />
            <Skeleton width="4rem" height="2rem" v-else />
          </div>
        </div>
      </div>
    </div>

    <div class="p-4" v-if="panel?.component" :id="`panel-content-${index}`">
      <div v-if="!loading">
        <component :is="panel.component" v-bind="panel.props" />
      </div>

      <div class="flex w-full" v-else>
        <ContentSkeleton />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PagePanel } from '@centric-os/types';
import { computed, type PropType } from 'vue';
import ContentSkeleton from '../page-accordion/components/content-skeleton/ContentSkeleton.vue';
import { isEmpty } from 'lodash';

const props = defineProps({
  panel: {
    type: Object as PropType<PagePanel>,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
  headerClass: {
    type: Array as PropType<string[]>,
    default: () => [] as string[],
  },
});

// Computed for header class
const headerClasses = computed<string[]>(() => {
  const rootHeaderClass: string[] = ['header'];

  if (!isEmpty(props.headerClass)) {
    return [...rootHeaderClass, ...props.headerClass];
  }

  return rootHeaderClass;
});
</script>

<style lang="scss" scoped>
@import '@centric-os/styles';

.header-title {
  color: $h2HeaderColor;
}

.header-subtitle {
  @extend .text-500, .font-normal, .text-xs, .mt-1;
}
</style>
