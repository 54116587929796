import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../enums';
import { beforeEnter } from '../guards';

const Component = () => import('./SiteSettings.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.SITE, SitesRoutePathNames.SETTINGS),
  name: SitesRouteNames.SITE_SETTINGS,
  component: Component,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;
