import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../enums';
import { beforeEnter } from './../guards';

const Component = () => import('./AppConfig.vue');

export default {
  path: buildChildURL(
    SitesRoutePathNames.SITE,
    SitesRoutePathNames.STATION_DETAILS,
    SitesRoutePathNames.STATION,
    SitesRoutePathNames.INTEGRATION_TYPE,
  ),
  name: SitesRouteNames.SITE_STATION_APP_CONFIG,
  component: Component,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;
