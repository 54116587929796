// stores/formStore.ts
import type { FormState } from '@centric-os/types';
import { get, isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import type { FormContext } from 'vee-validate';
import { computed, reactive, type ComputedRef } from 'vue';

/**
 * Pinia store to manage form states.
 */
export const useFormStore = defineStore('formStore', () => {
  /**
   * Reactive object to store form states, keyed by form ID.
   *
   * @type {Record<string, FormState<any, any>>}
   */
  const forms = reactive<Record<string, FormState<any, any>>>({});

  /**
   * Stores the form state in the store.
   *
   * @template TValues - The type of the form's values.
   * @template TOutput - The type of the form's output after validation.
   *
   * @param {string} id - The unique identifier for the form.
   * @param {FormContext<TValues, TOutput>} formState - The form context to store.
   */
  const setForm = <TValues, TOutput>(id: string, formState: FormContext<TValues, TOutput>) => {
    forms[id] = reactive({ form: formState });
  };

  /**
   * Retrieves the form state from the store reactively.
   *
   * @template TValues - The type of the form's values.
   * @template TOutput - The type of the form's output after validation.
   *
   * @param {string} id - The unique identifier for the form.
   * @returns {ComputedRef<FormContext<TValues, TOutput> | null>} - A computed value wrapping the form context, or null if not found.
   */
  const getForm = <TValues, TOutput>(
    id: string,
  ): ComputedRef<FormContext<TValues, TOutput> | null> => {
    return computed(() => (forms[id]?.form as FormContext<TValues, TOutput>) || null);
  };

  /**
   * Removes the form state from the store.
   *
   * @param {string} id - The unique identifier for the form.
   */
  const removeForm = (id: string) => {
    delete forms[id];
  };

  /**
   * Retrieves a form field value from the store reactively.
   * Behaves like vee-validate's `useFieldValue` function.
   * @template T - The type of the form field value.
   *
   * @param {string} formId - The unique identifier for the form.
   * @param {string} formKey - The key of the form field.
   * @returns {ComputedRef<T>} - A computed value wrapping the form field
   */
  const useFormFieldValue = <T>(formId: string, formKey: string): ComputedRef<T | undefined> => {
    const formValues = getForm(formId)?.value?.values;
    return computed<T | undefined>(() =>
      !isEmpty(formValues) ? get(formValues, formKey) : undefined,
    );
  };
  return {
    forms,
    setForm,
    getForm,
    removeForm,
    useFormFieldValue,
  };
});
