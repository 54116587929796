import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../enums';

const Component = () => import('./IntegrationUpdate.vue');

export default {
  path: buildChildURL(
	SitesRoutePathNames.SITE,
	SitesRoutePathNames.STATION_DETAILS,
	SitesRoutePathNames.STATION,
	SitesRoutePathNames.INTEGRATION_NAME,
  ),
  name: SitesRouteNames.SITE_STATION_VOLANTE,
  component: Component,

  children: [],
} as RouteRecordRaw;
