export enum SitesRoutePathNames {
  SITES = 'sites',
  LIST = '',
  SITE = ':siteId',
  STATION_SCHEDULE = 'station-schedule',
  STATION = ':stationId',
  MENU_HOURS = 'menu-hours',
  PICKUP_DETAILS = 'pickup-details',
  DROPOFF_DETAILS = 'dropoff-details',
  SALES = 'sales-channel',
  DEVICE_MAPPING = 'device-mapping',
  REPORTING = 'reporting',
  KDS = 'kds',
  FINANCIAL_CONFIG = 'financial-config',
  BUSINESS_UNIT = 'business-unit',
  BUSINESS_UNIT_ID = ':businessUnitId',
  STATION_DETAILS = 'station',
  INTEGRATION = 'integration',
  INTEGRATION_NAME = ':integration_name',
  NEXTEP = 'nextep',
  SETTINGS = 'site-settings',
  VOLANTE = 'volante',
  INTEGRATION_TYPE = ':integrationType',
}
