export interface MatchedSubstring {
  length: number;
  offset: number;
}

export interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: MatchedSubstring[];
  secondary_text: string;
}

export interface Term {
  offset: number;
  value: string;
}

export interface Place {
  description: string;
  matched_substrings: MatchedSubstring[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Term[];
  types: string[];
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface LookupLocation {
  lat: number;
  lng: number;
}

export interface Viewport {
  northeast: LookupLocation;
  southwest: LookupLocation;
}

export interface Geometry {
  location: LookupLocation;
  viewport: Viewport;
}

export interface PlusCode {
  compound_code: string;
  global_code: string;
}

export interface AddressModel {
  address_components: AddressComponent[];
  adr_address: string;
  formatted_address: string;
  geometry: Geometry;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  place_id: string;
  plus_code: PlusCode;
  reference: string;
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
}

// CD Address
export interface Address {
  // Suite number of the address, if applicable
  suite?: string;
  // First line of the address, should include street number
  address?: string;
  // City of the address
  city?: string;
  // State of the address, also province in Canada
  state?: string;
  // Country of the address
  country?: string;
  // Zip code or postal code of the address
  zip?: string;
  coordinates?: {
    // latitude of the the address
    latitude?: number;
    // longitude of the the address
    longitude?: number;
  };
}

/**
 * Country codes
 */
export enum CountryCode {
  US = 'US',
  CA = 'CA',
}
