// Enum for column keys
export enum DiscountColumnKeys {
  NAME = 'name',
  STATUS = 'status',
  VISIBLE = 'visible',
  SCHEDULE_START = 'scheduleStart',
  SCHEDULE_END = 'scheduleEnd',
  SITES = 'sites',
  BRANDS = 'brands',
  VALUE = 'value',
  APPLIES_TO = 'applies_to',
  CREATED = 'created',
  CREATED_BY = 'created_by',
  UPDATED = 'updated',
  UPDATED_BY = 'updated_by',
}

export enum DiscountViews {
  LIVE = 'live',
  PAST = 'past',
  DRAFT = 'draft',
}

export enum DiscountStatuses {
  LIVE = 'live',
  SCHEDULED = 'scheduled',
  READY = 'ready_to_publish',
  INCOMPLETE = 'incomplete',
  PAST = 'expired',
  PAUSED = 'paused',
}
