<template>
  <Dialog
    v-model:visible="isVisible"
    :position="position"
    :breakpoints="breakpoints"
    :closeOnEscape="false"
    :pt="{
      root: { class: rootClass },
      header: { class: 'hidden' },
      content: { class: contentClass, style: { gap: '1rem' } },
    }"
  >
    <div v-for="(slot, index) in Object.keys($slots)" :key="index">
      <slot :name="slot" />
    </div>

    <Button
      v-if="isEmpty($slots)"
      :label="primaryActionLabel"
      text
      :loading="loading"
      @click="emit('primaryAction')"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, ref, useAttrs } from 'vue';
import { isEmpty } from 'lodash-es';
import { Dialog } from '../../../core/overlays/dialog-modal';
import { Button } from '../../../core/buttons/button';
import type { DialogProps } from 'primevue/dialog';

defineOptions({ inheritAttrs: false });

const emit = defineEmits(['primaryAction']);

withDefaults(
  defineProps<
    {
      primaryActionLabel?: string;
      loading?: boolean;
    } & Pick<DialogProps, 'position' | 'breakpoints'>
  >(),
  {
    primaryActionLabel: 'Save',
    position: 'bottom',
  },
);

const attrs = useAttrs();

const isVisible = ref<boolean>(false);

const open = (): void => {
  isVisible.value = true;
};
const close = (): void => {
  isVisible.value = false;
};

const rootClass = computed<string[]>(() => {
  const baseClasses = 'border-round-xl overflow-hidden';
  const attrClasses = (attrs.rootClass as string) || '';
  return [baseClasses, attrClasses];
});

const contentClass = computed<string[]>(() => {
  const baseClasses = 'py-2 flex align-items-center';
  const attrClasses = (attrs.class as string) || '';
  return [baseClasses, attrClasses];
});

defineExpose({
  open,
  close,
});
</script>
