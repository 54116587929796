import { DateTime } from 'luxon';
import { SortOrder, type PaginationConfig, type QueryParams } from '../records';
import { DiscountViews } from './types';

export interface DiscountsPagination extends PaginationConfig {
  queryParams?: DiscountsQueryParams;
}

export interface DiscountsQueryParams extends QueryParams {
  start_date_start?: string;
  start_date_end?: string;
  end_date_start?: string;
  end_date_end?: string;
  last_modified_start?: string;
  status?: DiscountViews;
  limit?: number;
  page?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
}

export const discountsListPaginationConfig: DiscountsPagination = {
  queryParams: {
    status: DiscountViews.LIVE,
    end_date_start: DateTime.local().toJSDate().toISOString(),
    end_date_end: DateTime.local().plus({ days: 365 }).endOf('day').toJSDate().toISOString(),
    limit: 25,
    page: 1,
    sortBy: 'end_date_start',
    sortOrder: SortOrder.DESC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};
