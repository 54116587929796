<template>
  <FieldValidator :name="name">
    <template v-slot="{ value, handleChange, isInvalid, errorMessage }">
      <InputNumber
        :modelValue="value || value === 0 ? Number(value) : null"
        @update:modelValue="handleChange"
        :class="{ 'p-invalid': isInvalid }"
        v-bind="$attrs"
        ref="inputNumber"
        @keydown.enter="handleEnter"
      >
        <template v-if="label" #label>{{ label }}</template>
        <template v-if="endAdornment" #endAdornment
          ><i class="material-symbols-rounded text-xl">{{ endAdornment }}</i></template
        >
        <template v-if="rightSideLabel" #rightSideLabel>{{ rightSideLabel }}</template>
        <template v-if="isInvalid" #helperText>{{ errorMessage }}</template>
      </InputNumber>
    </template>
  </FieldValidator>
</template>

<script lang="ts">
export default { name: 'NumberField', inheritAttrs: false };
</script>

<script setup lang="ts">
import { InputNumber } from './../../../core/controls';
import { FieldValidator } from './../field-validator';
import { ref } from 'vue';
import useSubmitFormProvider from '../../../core/controls/composables/input/use-submit-form-provider';

defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  rightSideLabel: {
    type: String,
  },
  /**
   * Material UI icon string for end adornment
   */
  endAdornment: {
    type: String,
  },
});

const inputNumber = ref<InstanceType<typeof InputNumber>>();

const { handleEnter } = useSubmitFormProvider();
defineExpose({ inputNumber });
</script>
