export enum SitesRouteNames {
  SITES_ROOT = 'SitesRoot',
  SITES_LIST = 'SitesRoot/Sites',
  SITE_DETAILS = 'SitesRoot/Sites/Site/Details',
  SITE_MENU_HOURS = 'SitesRoot/Sites/Site/Details/MenuHours',
  SITE_PICKUP_DETAILS = 'SitesRoot/Sites/Site/PickupDetails',
  SITE_DROPOFF_DETAILS = 'SitesRoot/Sites/Site/DropoffDetails',
  SITE_REPORTING = 'SitesRoot/Sites/Site/Reporting',
  SITE_SALES_CHANNEL = 'SitesRoot/Sites/Site/SalesChannel',
  SITE_DEVICE_MAPPING = 'SitesRoot/Sites/Site/DeviceMapping',
  SITE_KDS = 'SitesRoot/Sites/Site/KDS',
  SITE_FINANCIAL_CONFIG = 'SitesRoot/Sites/Site/FinancialConfig',
  SITE_STATION_SCHEDULE = 'SitesRoot/Sites/Site/StationSchedule',
  SITE_BUSINESS_UNIT = 'SitesRoot/Sites/Site/BusinessUnit',
  SITE_STATION = 'SitesRoot/Sites/Site/Station',
  SITE_STATION_NEXTEP = 'SitesRoot/Sites/Site/Station/Nextep',
  SITE_STATION_APP_CONFIG = 'SitesRoot/Sites/Site/Station/AppConfig',
  SITE_SETTINGS = 'SitesRoot/Sites/Site/Settings',
  SITE_STATION_INTEGRATION = 'SitesRoot/Sites/Site/Station/Integration',
  SITE_STATION_AGILYSYS = 'SitesRoot/Sites/Site/Station/Agilysys',
  SITE_STATION_VOLANTE = 'SitesRoot/Sites/Site/Station/Volante',
}
