import {
  MenuRoles,
  MenuScopeActions,
  type RecordEntityId,
  SortOrder,
  type PaginationConfig,
} from '../../api';

export const defaultUsersPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export enum UserRoles {
  ADMIN = 'admin',
  SYS_ADMIN = 'sys_admin',
  IM_USER = 'im_user',
  SITE_OPERATOR = 'site_operator',
  MENU_USER = 'menu_user',
  DC_TEAM = 'dc_team',
  RUNNER = 'runner',
}

export const CustomPermissions = {
  overridePlu: 'override_plu:menu:*',
  renameLocalMenu: 'write_label:menu:*',
  editItemDescription: 'write_item_desc:menu:*',
  editCalories: 'edit_calories:menu:*',
  renameLocalItems: 'write_item_label:menu:*',
  refundOrders: 'write:order:*',
  kickMenu: 'kick:menu:*',
  canAccessReports: 'report:c360:*',
  canAccessProductReport: 'report:c360:product_mix',
  canAccessProductDistribution: 'report:c360:sales_distribution',
  canAccessProductSummary: 'report:c360:sales_summary',
  menuAccess: '*:menu:*',
  editBrandVisibility: 'hide:brand:*',
  toggleMenuEnabled: 'write_menu_enabled:calendar:*',
  allowReadSchedule: 'read_menu_schedule:calendar:*',
  allowSchedule: 'write_menu_schedule:calendar:*',
  assignBrandMenus: 'assign_menu:brand:*',
  editBrandTimeslots: 'edit_timeslots:brand:*',
  readAnalytics: 'read:analytics:*',
  allowWriteAnnouncement: 'write:announcement:*',
  viewPaymentInfo: 'read:payment:*',
  canDeleteStations: 'delete:centriclocation:*',
  canManageDiscount: 'write:discount:*',
  canCreateStations: 'write:centriclocation:*',
  writeDeliverLocations: 'write:deliverydestination:*',
  readOrders: 'read:order:*',
};

export const MenuPermissionTypes = {
  globalMenuGroup: 'global_menu_group',
  localMenuGroup: 'local_menu_group',
};

export const MenuRolePermissions = {
  [MenuRoles.GLOBAL_MENU_GROUP_ADMIN]: [
    `*:menu_v3:role`,
    `${MenuRoles.GLOBAL_MENU_GROUP_ADMIN}:menu_v3:role`,
    `*:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.LOCAL_MENU_GROUP_ADMIN]: [
    `${MenuRoles.LOCAL_MENU_GROUP_ADMIN}:menu_v3:role`,
    `${MenuRoles.LOCAL_MENU_GROUP_MANAGER}:menu_v3:role`,
    `${MenuScopeActions.READ}:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.LOCAL_MENU_GROUP_LIMITED_EDITS]: [
    `${MenuRoles.LOCAL_MENU_GROUP_LIMITED_EDITS}:menu_v3:role`,
    `${MenuScopeActions.WRITE_NESTED_IS_ACTIVE}:${MenuPermissionTypes.localMenuGroup}:*`,
    `${MenuScopeActions.WRITE_NESTED_PUBLISH}:${MenuPermissionTypes.localMenuGroup}:*`,
    `${MenuScopeActions.READ}:${MenuPermissionTypes.localMenuGroup}:*`,
    `${MenuScopeActions.SITE_ENABLED_WRITE_NESTED_IS_ACTIVE}:${MenuPermissionTypes.localMenuGroup}:*`,
    `${MenuScopeActions.SITE_ENABLED_WRITE_NESTED_PUBLISH}:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.GLOBAL_MENU_GROUP_MANAGER]: [
    `${MenuRoles.GLOBAL_MENU_GROUP_MANAGER}:menu_v3:role`,
    `${MenuScopeActions.READ}:${MenuPermissionTypes.globalMenuGroup}:*`,
    `*:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
  [MenuRoles.LOCAL_MENU_GROUP_MANAGER]: [
    `${MenuRoles.LOCAL_MENU_GROUP_MANAGER}:menu_v3:role`,
    `${MenuScopeActions.SITE_ENABLED_WRITE_NESTED}:${MenuPermissionTypes.localMenuGroup}:*`,
  ],
};

export const DefaultPermissions = {
  [UserRoles.ADMIN]: [
    CustomPermissions.canAccessReports,
    CustomPermissions.refundOrders,
    ...MenuRolePermissions[MenuRoles.LOCAL_MENU_GROUP_ADMIN],
  ],
  [UserRoles.SITE_OPERATOR]: [
    CustomPermissions.canAccessReports,
    CustomPermissions.refundOrders,
    ...MenuRolePermissions[MenuRoles.LOCAL_MENU_GROUP_MANAGER],
  ],
  [UserRoles.RUNNER]: [CustomPermissions.readOrders],
};

export interface StationSeletion {
  id: RecordEntityId;
  name: string;
  selected: boolean;
}

export interface SiteSelection {
  id: RecordEntityId;
  name: string;
  // Property to flat station brands
  brands: StationSeletion[];
}

export interface BrandSelection {
  id: RecordEntityId;
  name: string;
  selected: boolean;
}

export interface GlobalMenuGroupSelection {
  id: RecordEntityId;
  name: string;
  // Property to flat station brands
  draft_brands: BrandSelection[];
}

export interface LocalMenuGroupSelection {
  id: RecordEntityId;
  name: string;
  // Property to flat station brands
  draft_brands: BrandSelection[];
}
