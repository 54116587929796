import {
  createStore,
  PaginationPlugin,
  AbortControllerPlugin,
  APIPlugin,
  piniaResetDispose,
} from '@centric-os/stores';
import api, { cdlApi, genericAPI } from './../api';
import PersiststatePlugin from 'pinia-plugin-persistedstate';

const pinia = createStore();

pinia.use(APIPlugin({ api, cdlApi, genericAPI }));
pinia.use(AbortControllerPlugin());
pinia.use(piniaResetDispose);
pinia.use(PaginationPlugin());
pinia.use(PersiststatePlugin);

export default pinia;
