import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../enums';
import { beforeEnter } from './../../guards';

const Component = () => import('./IntegrationUpdate.vue');

export default {
  path: buildChildURL(
    SitesRoutePathNames.SITE,
    SitesRoutePathNames.STATION_DETAILS,
    SitesRoutePathNames.STATION,
    SitesRoutePathNames.INTEGRATION,
    SitesRoutePathNames.INTEGRATION_NAME,
  ),
  name: SitesRouteNames.SITE_STATION_INTEGRATION,
  component: Component,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;
