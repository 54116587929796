import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SitesRouteNames } from '@centric-os/sites';
import { useCompassDigitalID } from '@centric-os/helpers';
import { useAuthStore } from '@centric-os/stores';
import { P2Role } from '@centric-os/types';

/**
 * Navigation guard for the Announcements route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route being navigated away from.
 * @param next - The function to resolve the navigation.
 */
export const beforeEnter = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const authStore = useAuthStore();
    const siteOperatorIds = authStore.getScopeIds('site_operator_role', 'group');
    const IMRoleIds = authStore.getScopeIds('im_user_role', 'group');

    const { valid } = useCompassDigitalID();
    const siteID: string = to.params.siteId as string;

    if (valid(siteID) && (authStore.hasRole(P2Role.ADMIN) || siteOperatorIds.includes(siteID) || IMRoleIds.includes(siteID))) {
      return next();
    } else {
      return next({
        name: SitesRouteNames.SITES_LIST,
        replace: true,
      });
    }
  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    next(false); // Cancel navigation
  }
};
