import type { RouteRecordRaw } from 'vue-router';

import sitesListRoute from './sites-list';
import singleSiteRoute from './site-details';
import stationSchedule from './station-schedule';
import businessUnitUpdate from './business-unit-update';
import station from './station';
import siteSettings from './site-settings';
import nextepUpdate from './integration-update/nextep';
import volanteUpdate from './integration-update/volante';
import appConfig from './app-config';

export default [
  sitesListRoute,
  singleSiteRoute,
  stationSchedule,
  businessUnitUpdate,
  siteSettings,
  station,
  nextepUpdate,
  volanteUpdate,
  appConfig,
] as RouteRecordRaw[];

export * from './stores';
