<template>
  <div class="c-tab-menu">
    <PVTabMenu ref="tabMenu" v-bind="$attrs">
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot v-if="slot" :name="slot" v-bind="scope" />
      </template>
    </PVTabMenu>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PVTabMenu from 'primevue/tabmenu';
import { useResizeObserver } from '@vueuse/core';

const tabMenu = ref(null);

useResizeObserver(tabMenu, () => tabMenu.value.updateInkBar());

defineExpose({
  tabMenu,
});
</script>

<script lang="ts">
export default { name: 'TabMenu', inheritAttrs: false };
</script>
