import type { PiniaPluginContext } from 'pinia';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $resetAndDispose: () => void;
  }
}

export const piniaResetDispose = ({ store }: PiniaPluginContext) => {
  store.$resetAndDispose = () => {
    store.$reset();
    store.$dispose();
  };
};
